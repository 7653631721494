document.addEventListener("DOMContentLoaded", function () {
  const creditRadioButtons = document.querySelectorAll('input[name="sale[credit]"]');
  const auctionItem = document.querySelector(".auction-item");
  const lotNumberItem = document.querySelector(".lot-number-item");
  const carNumber = document.querySelector(".car-number");
  const auctionDateWrapper = document.querySelector(".auction-date-wrapper");

  function toggleFieldsBasedOnCredit() {
    const selectedCreditInput = document.querySelector('input[name="sale[credit]"]:checked');
    const isCreditActive = selectedCreditInput && selectedCreditInput.value === "true";

    if (isCreditActive) {
      lotNumberItem.style.display = 'none';
      lotNumberItem.querySelector('input').removeAttribute('required');
    } else {
      lotNumberItem.style.display = 'block';
      lotNumberItem.querySelector('input').setAttribute('required', true);
    }

    if (isCreditActive) {
      auctionItem.style.display = 'none';
      auctionItem.querySelector('select').removeAttribute('required');
    } else {
      auctionItem.style.display = 'block';
      auctionItem.querySelector('select').setAttribute('required', true);
    }

    if (isCreditActive) {
      carNumber.style.display = 'block';
    } else {
      carNumber.style.display = 'none';
    }

    if (isCreditActive) {
      auctionDateWrapper.style.display = 'none';
      auctionDateWrapper.querySelector('input[type="date"]').removeAttribute('required');
      auctionDateWrapper.querySelector('input[type="time"]').removeAttribute('required');
    } else {
      auctionDateWrapper.style.display = 'block';
      auctionDateWrapper.querySelector('input[type="date"]').setAttribute('required', true);
      auctionDateWrapper.querySelector('input[type="time"]').setAttribute('required', true);
    }

    if (!selectedCreditInput) {
      carNumber.style.display = 'none';
    }
  }

  creditRadioButtons.forEach(radio => {
    radio.addEventListener('change', toggleFieldsBasedOnCredit);
  });

  toggleFieldsBasedOnCredit();
});
