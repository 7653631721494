document.addEventListener('DOMContentLoaded', () => {
  const updateCheckedMonths = (saleId, month, value) => {
    fetch(`/sales/${saleId}/update_checked_months`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        sale: { checked_months: { [month]: value } }
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'ok') {
          const box = document.querySelector(`.delivery-box[data-sale-id="${saleId}"][data-month="${month}"]`);
          if (box) {
            box.classList.remove('delivery-bank', 'delivery-empty', 'delivery-default');
            if (value) {
              box.classList.add(value === 'bank' ? 'delivery-bank' : 'delivery-empty');
            } else {
              box.classList.add('delivery-default');
            }
          }

          const countElement = document.querySelector(`.checked-months-count[data-sale-id="${saleId}"]`);
          if (countElement) {
            countElement.textContent = `${Object.values(data.checked_months || {}).filter(v => v).length}/${countElement.dataset.totalMonths}`;
          }
        } else {
          console.error('Failed to update checked months:', data.errors);
        }
      })
      .catch(error => console.error('Error updating checked months:', error));
  };

  document.querySelectorAll('.checkbox-bank, .checkbox-empty').forEach(checkbox => {
    checkbox.addEventListener('change', event => {
      const checkbox = event.target;
      const saleId = checkbox.dataset.saleId;
      const month = checkbox.dataset.month;
      const value = checkbox.checked ? checkbox.value : null;

      document.querySelectorAll(`.checkbox-bank[data-sale-id="${saleId}"][data-month="${month}"], .checkbox-empty[data-sale-id="${saleId}"][data-month="${month}"]`).forEach(otherCheckbox => {
        if (otherCheckbox !== checkbox) {
          otherCheckbox.checked = false;
        }
      });

      updateCheckedMonths(saleId, month, value);
    });
  });
});
