//======================= pop-up ================================

document.addEventListener('DOMContentLoaded', function () {
	var newSalePopupOverlay = document.querySelector('#new-sale-popup');
	var closeNewSaleBtn = document.querySelector('#close-new-sale-popup');
	var openNewSalePopupBtn = document.querySelector('#open-new-sale-popup');

	if (newSalePopupOverlay) {
		function openNewSalePopup() {
			newSalePopupOverlay.style.visibility = 'visible';
		}
		function closeNewSalePopup() {
			newSalePopupOverlay.style.visibility = 'hidden';
		}

		closeNewSaleBtn.addEventListener('click', closeNewSalePopup);

		newSalePopupOverlay.addEventListener('click', function (event) {
			if (event.target === newSalePopupOverlay) {
				closeNewSalePopup();
			}
		});

		openNewSalePopupBtn.addEventListener('click', openNewSalePopup);
	}
});


document.addEventListener('DOMContentLoaded', function () {
	var newOrderPopup = document.querySelector('#new-order-popup');
	var closeNewOrderBtn = document.querySelector('#close-new-order-popup');
	var openNewOrderPopupBtn = document.querySelector('#open-new-order-popup');

	if (newOrderPopup) {
		function openNewOrderPopup() {
			newOrderPopup.style.visibility = 'visible';
		}

		function closeNewOrderPopup() {
			newOrderPopup.style.visibility = 'hidden';
		}

		closeNewOrderBtn.addEventListener('click', closeNewOrderPopup);

		newOrderPopup.addEventListener('click', function (event) {
			if (event.target === newOrderPopup) {
				closeNewOrderPopup();
			}
		});

		openNewOrderPopupBtn.addEventListener('click', openNewOrderPopup);
	}
});


document.addEventListener('DOMContentLoaded', function () {
	var deliveryPopup = document.querySelector('#delivery-popup');
	var closeDeliveryBtn = document.querySelector('#close-delivery-popup');
	var openDeliveryBtn = document.querySelector('#open-delivery-popup');

	if (deliveryPopup) {
		function openDeliveryPopup() {
			deliveryPopup.style.visibility = 'visible';
		}

		function closeDeliveryPopup() {
			deliveryPopup.style.visibility = 'hidden';
		}

		closeDeliveryBtn.addEventListener('click', closeDeliveryPopup);

		deliveryPopup.addEventListener('click', function (event) {
			if (event.target === deliveryPopup) {
				closeDeliveryPopup();
			}
		});

		openDeliveryBtn.addEventListener('click', openDeliveryPopup);
	}
});

document.addEventListener('DOMContentLoaded', function () {
	var customsPopup = document.querySelector('#customs-popup');
	var closeCustomsBtn = document.querySelector('#close-customs-popup');
	var openCustomsBtn = document.querySelector('#open-customs-popup');

	if (customsPopup) {
		function openCustomsPopup() {
			customsPopup.style.visibility = 'visible';
		}

		function closeCustomsPopup() {
			customsPopup.style.visibility = 'hidden';
		}

		closeCustomsBtn.addEventListener('click', closeCustomsPopup);

		customsPopup.addEventListener('click', function (event) {
			if (event.target === customsPopup) {
				closeCustomsPopup();
			}
		});

		openCustomsBtn.addEventListener('click', openCustomsPopup);
	}
});

document.addEventListener('DOMContentLoaded', function () {
	var finalsPopup = document.querySelector('#finals-popup');
	var closeFinalsBtn = document.querySelector('#close-finals-popup');
	var openFinalsBtn = document.querySelector('#open-finals-popup');
	
	if (finalsPopup) {
		function openFinalsPopup() {
			finalsPopup.style.visibility = 'visible';
		}

		function closeFinalsPopup() {
			finalsPopup.style.visibility = 'hidden';
		}

		closeFinalsBtn.addEventListener('click', closeFinalsPopup);

		finalsPopup.addEventListener('click', function (event) {
			if (event.target === finalsPopup) {
				closeFinalsPopup();
			}
		});

		openFinalsBtn.addEventListener('click', openFinalsPopup);
	}
});


const openMainCardApplication = document.getElementById('open-main-card-application');
const openMainCardSales = document.getElementById('open-main-card-sales');
const openMainCardDeposits = document.getElementById('open-main-card-deposites'); 
const mainCardApplication = document.getElementById('main-card-application');
const mainCardSales = document.getElementById('main-card-sales');
const mainCardDeposits = document.getElementById('main-card-deposites'); 

if (openMainCardApplication && openMainCardSales && openMainCardDeposits) {
	openMainCardApplication.addEventListener('click', function (event) {
		event.preventDefault();

		mainCardApplication.style.display = 'block';
		mainCardSales.style.display = 'none';
		mainCardDeposits.style.display = 'none'; 

		openMainCardApplication.classList.add('active');
		openMainCardSales.classList.remove('active');
		openMainCardDeposits.classList.remove('active'); 
	});

	openMainCardSales.addEventListener('click', function (event) {
		event.preventDefault();

		mainCardApplication.style.display = 'none';
		mainCardSales.style.display = 'block';
		mainCardDeposits.style.display = 'none'; 

		openMainCardSales.classList.add('active');
		openMainCardApplication.classList.remove('active');
		openMainCardDeposits.classList.remove('active'); 
	});

	openMainCardDeposits.addEventListener('click', function (event) { 
		event.preventDefault();

		mainCardApplication.style.display = 'none';
		mainCardSales.style.display = 'none';
		mainCardDeposits.style.display = 'block'; 

		openMainCardDeposits.classList.add('active');
		openMainCardApplication.classList.remove('active');
		openMainCardSales.classList.remove('active'); 
	});
}



document.addEventListener('DOMContentLoaded', function () {
	var clientCardSalesPopup = document.querySelector('#client-card-sales-popup');
	var closeClientCardSalesBtn = document.querySelector('#close-client-card-sales-popup');
	var openClientCardSalesBtns = document.querySelectorAll('.open-client-card-sales-popup');

	if (clientCardSalesPopup) {
		function openClientCardSalesPopup() {
			clientCardSalesPopup.style.visibility = 'visible';
		}

		function closeClientCardSalesPopup() {
			clientCardSalesPopup.style.visibility = 'hidden';
		}

		closeClientCardSalesBtn.addEventListener('click', closeClientCardSalesPopup);

		clientCardSalesPopup.addEventListener('click', function (event) {
			if (event.target === clientCardSalesPopup) {
				closeClientCardSalesPopup();
			}
		});

		openClientCardSalesBtns.forEach(button => {
			button.addEventListener('click', function() {
				openClientCardSalesPopup();
			});
		});
	}
});



document.addEventListener('DOMContentLoaded', function () {
	var newOfferPopup = document.getElementById('new-offer-popup');
	var closeNewOfferBtn = document.getElementById('close-new-offer-popup');
	var openNewOfferBtns = document.querySelectorAll('.payment-box p.final-price#open-new-offer-popup');

	if (newOfferPopup) {
		function openNewOfferPopup(saleId, auction) {
			var saleIdInput = document.getElementById('sale-id-offer');
			var auctionInput = document.getElementById('final-bid-form-id')
			if (saleIdInput && saleId) {
				saleIdInput.value = saleId;
			}
			if (auctionInput && auction) {
				auctionInput.className = auction === 'ca_iaai' ? 'form-item cad-input' : 'form-item budget-input'
			}
			newOfferPopup.style.visibility = 'visible';
		}

		function closeNewOfferPopup() {
			newOfferPopup.style.visibility = 'hidden';
		}

		closeNewOfferBtn.addEventListener('click', closeNewOfferPopup);

		newOfferPopup.addEventListener('click', function (event) {
			if (event.target === newOfferPopup) {
				closeNewOfferPopup();
			}
		});

		openNewOfferBtns.forEach(button => {
			button.addEventListener('click', function() {
				var saleId = this.getAttribute('data-sale-id');
				var auction = this.getAttribute('data-auction')
				openNewOfferPopup(saleId, auction);
			});
		});
	}
});

document.addEventListener('DOMContentLoaded', function() {
	const popups = [
		{ popupId: 'invoices-copart-popup', openButtonId: 'open-copart-popup', auction: 'copart' },
		{ popupId: 'invoices-iaai-popup', openButtonId: 'open-iaai-popup', auction: 'iaai' },
		{ popupId: 'invoices-manheim-popup', openButtonId: 'open-manheim-popup', auction: 'manheim' }
	];

	popups.forEach(({ popupId, openButtonId, auction }) => {
		var invoicesPopup = document.getElementById(popupId);
		var openInvoicesBtns = document.querySelectorAll(`.payment-box p.auction.open-invoices-popup#${openButtonId}`);

		if (invoicesPopup) {
			function openInvoicesPopup(saleId, finalBid) {
				let saleIdInput = document.getElementById(`sale-id-${auction}`);
				
				if (saleIdInput && saleId) {
						saleIdInput.value = saleId;
				}

				let finalBidInput = document.getElementById(`final-bid-${auction}`);
				if (finalBidInput) {
					finalBidInput.value = finalBid || '';
				}

				invoicesPopup.style.visibility = 'visible';
			}

			function closeInvoicesPopup() {
				invoicesPopup.style.visibility = 'hidden';
			}

			invoicesPopup.querySelectorAll('.close-invoices-popup').forEach(closeBtn => {
				closeBtn.addEventListener('click', closeInvoicesPopup);
			});

			invoicesPopup.addEventListener('click', function(event) {
				if (event.target === invoicesPopup) {
					closeInvoicesPopup();
				}
			});

			openInvoicesBtns.forEach(button => {
				button.addEventListener('click', function() {
					var saleId = this.getAttribute('data-sale-id');
					var finalBid = this.getAttribute('data-final-bid');
					openInvoicesPopup(saleId, finalBid);
				});
			});
		}
	});
});

document.addEventListener('DOMContentLoaded', function () {
	var clientsDataPopup = document.getElementById('clients-data-popup');
	var closeClientsDataBtn = document.getElementById('close-clients-data-popup');
	var openClientsDataBtn = document.getElementById('open-clients-data-popup');

	if (clientsDataPopup) {
		function openClientsDataPopup() {
			clientsDataPopup.style.visibility = 'visible';
		}

		function closeClientsDataPopup() {
			clientsDataPopup.style.visibility = 'hidden';
		}

		closeClientsDataBtn.addEventListener('click', closeClientsDataPopup);

		clientsDataPopup.addEventListener('click', function (event) {
			if (event.target === clientsDataPopup) {
				closeClientsDataPopup();
			}
		});

		openClientsDataBtn.addEventListener('click', openClientsDataPopup);
	}
});


document.addEventListener('turbo:frame-load', function () {
  if (document.querySelector('.client-individual-form')) {
    document.querySelector('.client-individual-form').style.display = 'none';
    document.querySelector('.client-legal-entity-form').style.display = 'none';
  
    var clientTypeRadios = document.querySelectorAll('input[name="detailable_type"]');

    clientTypeRadios.forEach(function (radio) {
      radio.addEventListener('change', function () {
        if (radio.id === 'client-individual') {
          document.querySelector('.client-individual-form').style.display = 'block';
          document.querySelector('.client-legal-entity-form').style.display = 'none';
        }
        else if (radio.id === 'client-legal-entity') {
          document.querySelector('.client-individual-form').style.display = 'none';
          document.querySelector('.client-legal-entity-form').style.display = 'block';
        }
      });
    });
  }
});

document.addEventListener('turbo:frame-load', function () {
  function toggleFormSections() {
    var clientTypeRadios = document.querySelectorAll('input[name="detailable_type"]');
    clientTypeRadios.forEach(function (radio) {
      if (radio.checked) {
        if (radio.id === 'client-individual') {
          document.querySelector('.client-individual-form').style.display = 'block';
          document.querySelectorAll('.client-individual-form .required-field').forEach(function(field) {
            field.setAttribute('required', 'true');
          });
          document.querySelector('.client-legal-entity-form').style.display = 'none';
          document.querySelectorAll('.client-legal-entity-form .required-field').forEach(function(field) {
            field.removeAttribute('required');
          });
        } else if (radio.id === 'client-legal-entity') {
          document.querySelector('.client-individual-form').style.display = 'none';
          document.querySelectorAll('.client-individual-form .required-field').forEach(function(field) {
            field.removeAttribute('required');
          });
          document.querySelector('.client-legal-entity-form').style.display = 'block';
          document.querySelectorAll('.client-legal-entity-form .required-field').forEach(function(field) {
            field.setAttribute('required', 'true');
          });
        }
      }
    });
  }

  if (document.querySelector('.client-individual-form') || document.querySelector('.client-legal-entity-form')) {
    var clientTypeRadios = document.querySelectorAll('input[name="detailable_type"]');
    clientTypeRadios.forEach(function (radio) {
      radio.addEventListener('change', toggleFormSections);
    });
    toggleFormSections(); // Call the function to check the state on load or frame change
  }
});


document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.open-sales-auction-block').forEach(function(button) {
    button.addEventListener('click', function(event) {
      event.preventDefault();
      const parentCard = this.closest('.main-card');
      parentCard.querySelector('.sales-auction-block').style.display = 'block';
      parentCard.querySelector('.personal-data-block').style.display = 'none';
      parentCard.querySelector('.calculation-block').style.display = 'none';

      parentCard.querySelector('.open-sales-auction-block').classList.add('active');
      parentCard.querySelector('.open-personal-data-block').classList.remove('active');
      parentCard.querySelector('.open-calculation-block').classList.remove('active');
    });
  });

  document.querySelectorAll('.open-personal-data-block').forEach(function(button) {
    button.addEventListener('click', function(event) {
      event.preventDefault();
      const parentCard = this.closest('.main-card');
      parentCard.querySelector('.sales-auction-block').style.display = 'none';
      parentCard.querySelector('.personal-data-block').style.display = 'block';
      parentCard.querySelector('.calculation-block').style.display = 'none';

      parentCard.querySelector('.open-personal-data-block').classList.add('active');
      parentCard.querySelector('.open-sales-auction-block').classList.remove('active');
      parentCard.querySelector('.open-calculation-block').classList.remove('active');
    });
  });

  document.querySelectorAll('.open-calculation-block').forEach(function(button) {
    button.addEventListener('click', function(event) {
      event.preventDefault();
      const parentCard = this.closest('.main-card');
      parentCard.querySelector('.sales-auction-block').style.display = 'none';
      parentCard.querySelector('.personal-data-block').style.display = 'none';
      parentCard.querySelector('.calculation-block').style.display = 'block';

      parentCard.querySelector('.open-calculation-block').classList.add('active');
      parentCard.querySelector('.open-sales-auction-block').classList.remove('active');
      parentCard.querySelector('.open-personal-data-block').classList.remove('active');
    });
  });
});

const openSalesAuctionBlock = document.getElementById('open-sales-auction-block');
const openPersonalDataBlock = document.getElementById('open-personal-data-block');
const openCalculationBlock = document.getElementById('open-calculation-block');
const salesAuctionBlock = document.getElementById('sales-auction-block');
const personalDataBlock = document.getElementById('personal-data-block');
const calculationBlock = document.getElementById('calculation-block');

if (openSalesAuctionBlock && openPersonalDataBlock && openCalculationBlock) {
	openSalesAuctionBlock.addEventListener('click', function (event) {
		event.preventDefault();

		salesAuctionBlock.style.display = 'block';
		personalDataBlock.style.display = 'none';
		calculationBlock.style.display = 'none';

		openSalesAuctionBlock.classList.add('active');
		openPersonalDataBlock.classList.remove('active');
		openCalculationBlock.classList.remove('active');
	});

	openPersonalDataBlock.addEventListener('click', function (event) {
		event.preventDefault();

		salesAuctionBlock.style.display = 'none';
		personalDataBlock.style.display = 'block';
		calculationBlock.style.display = 'none';

		openPersonalDataBlock.classList.add('active');
		openSalesAuctionBlock.classList.remove('active');
		openCalculationBlock.classList.remove('active');
	});

	openCalculationBlock.addEventListener('click', function (event) {
		event.preventDefault();

		salesAuctionBlock.style.display = 'none';
		personalDataBlock.style.display = 'none';
		calculationBlock.style.display = 'block';

		openCalculationBlock.classList.add('active');
		openSalesAuctionBlock.classList.remove('active');
		openPersonalDataBlock.classList.remove('active');
	});
}

document.addEventListener('DOMContentLoaded', function () {
	var newDepositPopup = document.getElementById('new-deposit-popup');
	var closeNewDepositBtn = document.getElementById('close-new-deposit-popup');
	var openNewDepositBtn = document.getElementById('open-new-deposit-popup');

	if (newDepositPopup) {
		function openNewDepositPopup() {
			newDepositPopup.style.visibility = 'visible';
		}

		function closeNewDepositPopup() {
			newDepositPopup.style.visibility = 'hidden';
		}

		closeNewDepositBtn.addEventListener('click', closeNewDepositPopup);

		newDepositPopup.addEventListener('click', function (event) {
			if (event.target === newDepositPopup) {
				closeNewDepositPopup();
			}
		});

		openNewDepositBtn.addEventListener('click', openNewDepositPopup);
	}
});

function toggleDepositForms() {
	if (document.querySelector('.deposite-market-form')) {
		document.querySelector('.deposite-market-form').style.display = 'none';
		document.querySelector('.deposite-leasing-form').style.display = 'none';

		var depositeTypeRadios = document.querySelectorAll('input[name="deposit[deposit_type]"]');

		depositeTypeRadios.forEach(function (radio) {
			if (radio.checked) {
				if (radio.value === 'market') {
					document.querySelector('.deposite-market-form').style.display = 'block';
				} else if (radio.value === 'leasing') {
					document.querySelector('.deposite-leasing-form').style.display = 'block';
				}
			}

			radio.addEventListener('change', function () {
				if (radio.value === 'market') {
					document.querySelector('.deposite-market-form').style.display = 'block';
					document.querySelector('.deposite-leasing-form').style.display = 'none';
				}
				else if (radio.value === 'leasing') {
					document.querySelector('.deposite-market-form').style.display = 'none';
					document.querySelector('.deposite-leasing-form').style.display = 'block';
				}
			});
		});
	}

	if (document.querySelector('.deposite-client-individual-form')) {
		document.querySelector('.deposite-client-individual-form').style.display = 'none';
		document.querySelector('.deposite-client-legal-entity-form').style.display = 'none';

		var depositeClientTypeRadios = document.querySelectorAll('input[name="deposit[client_type]"]');

		depositeClientTypeRadios.forEach(function (radio) {
			if (radio.checked) {
				if (radio.value === 'individual_client') {
					document.querySelector('.deposite-client-individual-form').style.display = 'block';
				} else if (radio.value === 'legal_client') {
					document.querySelector('.deposite-client-legal-entity-form').style.display = 'block';
				}
			}

			radio.addEventListener('change', function () {
				if (radio.value === 'individual_client') {
					document.querySelector('.deposite-client-individual-form').style.display = 'block';
					document.querySelector('.deposite-client-legal-entity-form').style.display = 'none';
				}
				else if (radio.value === 'legal_client') {
					document.querySelector('.deposite-client-individual-form').style.display = 'none';
					document.querySelector('.deposite-client-legal-entity-form').style.display = 'block';
				}
			});
		});
	}
}

document.addEventListener('DOMContentLoaded', toggleDepositForms);
document.addEventListener('turbo:frame-load', toggleDepositForms);

document.addEventListener('turbo:frame-load', function() {
  const receivedRadio = document.getElementById('received');
  const returnedRadio = document.getElementById('returned');
  const returnedDateContainer = document.getElementById('returned-date-container');

  function toggleReturnedDateContainer() {
    if (returnedRadio.checked) {
      returnedDateContainer.style.display = '';
    } else {
      returnedDateContainer.style.display = 'none';
    }
  }

  receivedRadio.addEventListener('change', toggleReturnedDateContainer);
  returnedRadio.addEventListener('change', toggleReturnedDateContainer);

  toggleReturnedDateContainer();
});




function checkPopupVisibility() {
	const popupOverlay = document.querySelector('.popup-overlay');
	const pageCrm = document.querySelector('.page-crm');

	if (popupOverlay && pageCrm) {
		const visibility = window.getComputedStyle(popupOverlay).visibility;
		if (visibility === 'visible') {
			pageCrm.style.overflowX = 'auto';
		} else {
			pageCrm.style.overflowX = '';
		}
	}
}


document.addEventListener('DOMContentLoaded', () => {
	checkPopupVisibility();

	const observer = new MutationObserver(checkPopupVisibility);
	const popupOverlay = document.querySelector('.popup-overlay');

	if (popupOverlay) {
		observer.observe(popupOverlay, { attributes: true, attributeFilter: ['style', 'class'] });
	}
});

//  ============================ For managing sales payments ============================

document.addEventListener('turbo:frame-load', function () {
  if (document.querySelector('.old-contract-format-form')) {
    document.querySelector('.old-contract-format-form').style.display = 'none';
    document.querySelector('.new-contract-format-form').style.display = 'none';
  
    var documentFormatRadios = document.querySelectorAll('input[name="payments[new_document_format]"]');

    documentFormatRadios.forEach(function (radio) {
      radio.addEventListener('change', function () {
        if (radio.id === 'old_format') {
          document.querySelector('.old-contract-format-form').style.display = 'block';
          document.querySelector('.new-contract-format-form').style.display = 'none';
        }
        else if (radio.id === 'new_format') {
          document.querySelector('.old-contract-format-form').style.display = 'none';
          document.querySelector('.new-contract-format-form').style.display = 'block';
        }
      });
    });
  }
});

document.addEventListener('turbo:frame-load', function () {
  function toggleFormSections() {
    var documentFormatRadios = document.querySelectorAll('input[name="payments[new_document_format]"]');
    documentFormatRadios.forEach(function (radio) {
      if (radio.checked) {
        if (radio.id === 'old_format') {
          document.querySelector('.old-contract-format-form').style.display = 'block';
          document.querySelectorAll('.old-contract-format-form .required-field').forEach(function(field) {
            field.setAttribute('required', 'true');
          });
          document.querySelector('.new-contract-format-form').style.display = 'none';
          document.querySelectorAll('.new-contract-format-form .required-field').forEach(function(field) {
            field.removeAttribute('required');
          });
        } else if (radio.id === 'new_format') {
          document.querySelector('.old-contract-format-form').style.display = 'none';
          document.querySelectorAll('.old-contract-format-form .required-field').forEach(function(field) {
            field.removeAttribute('required');
          });
          document.querySelector('.new-contract-format-form').style.display = 'block';
          document.querySelectorAll('.new-contract-format-form .required-field').forEach(function(field) {
            field.setAttribute('required', 'true');
          });
        }
      }
    });
  }

  if (document.querySelector('.old-contract-format-form') || document.querySelector('.new-contract-format-form')) {
    var documentFormatRadios = document.querySelectorAll('input[name="payments[new_document_format]"]');
    documentFormatRadios.forEach(function (radio) {
      radio.addEventListener('change', toggleFormSections);
    });
    toggleFormSections(); // Call the function to check the state on load or frame change
  }
});


document.addEventListener('DOMContentLoaded', function () {
	var creditPaymentsPopups = document.querySelectorAll('.credit-payments-popup');
	var openCreditPaymentsBtns = document.querySelectorAll('.open-credit-payments-popup');

	if (creditPaymentsPopups.length === 0 || openCreditPaymentsBtns.length === 0) {
		return;
	}

	function openCreditPaymentsPopup(popup) {
		popup.style.visibility = 'visible';
	}

	function closeCreditPaymentsPopup(popup) {
		popup.style.visibility = 'hidden';
	}

	openCreditPaymentsBtns.forEach(function (btn) {
		btn.addEventListener('click', function () {
			var popup = btn.closest('.delivery-box').querySelector('.credit-payments-popup');
			if (popup) {
				openCreditPaymentsPopup(popup);
			}
		});
	});

	creditPaymentsPopups.forEach(function (popup) {
		var closeBtn = popup.querySelector('.close-credit-payments-popup');

		if (closeBtn) {
			closeBtn.addEventListener('click', function () {
				closeCreditPaymentsPopup(popup);
			});
		}

		document.addEventListener('click', function (event) {
			if (event.target === popup) {
				closeCreditPaymentsPopup(popup);
			}
		});
	});
});
