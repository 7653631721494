import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["totalSum", "paymentField", "remaining", "auctionField", "auctionSum", "caCurrency"]

  connect() {
    this.calculateAuctionSum();
    this.updateRemaining();
  }

  updateRemaining() {
    let totalSum = parseFloat(this.totalSumTarget.value) || 0;
    let remainingAmount = totalSum;

    remainingAmount -= parseFloat(this.auctionSumTarget.textContent)

    this.paymentFieldTargets.forEach((field) => {
      const value = parseFloat(field.value) || 0;
      remainingAmount -= value;
    });

    this.remainingTarget.textContent = remainingAmount.toFixed();
  }

  calculateAuctionSum() {
    let auctionSum = 0;
    let caCurrency = 1;

    if (this.hasCaCurrencyTarget) {
      caCurrency = parseFloat(this.caCurrencyTarget.value) || 1;
    }
    this.auctionFieldTargets.forEach((field) => {
      const value = parseFloat(field.value) || 0;
      auctionSum += value;
    });

    this.auctionSumTarget.textContent = (auctionSum / caCurrency).toFixed();
    this.updateRemaining();
  }
}
